/**
 * Datart
 *
 * Copyright 2021
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { SheetComponent } from '@antv/s2-react';
import '@antv/s2-react/dist/style.min.css';
import React, {FC, memo, useCallback, useContext, useEffect, useImperativeHandle, useRef} from 'react';
import styled from 'styled-components/macro';
import { FONT_SIZE_LABEL } from 'styles/StyleConstants';
import { AndvS2Config } from './types';
import { copyData, download, SpreadSheet, copyData as getSheetData } from "@antv/s2";
import { Button } from "antd";
import {WidgetContext} from "../../../pages/DashBoardPage/components/WidgetProvider/WidgetProvider";
import {emitter} from "../../../utils/Emitter";

const AntVS2Wrapper: FC<AndvS2Config> = memo(
  ({ dataCfg, options, theme, palette }) => {

    const s2Ref = React.useRef<SpreadSheet>(null);

    // const selectedTab = useSelector(selectSelectedTab);

    const widget = useContext(WidgetContext);

    const downloadListener = useCallback(json => {
      if (s2Ref.current) {
        const data = getSheetData(s2Ref.current, ',', true);
        try {
          download(data, "fileName");
        } catch (err) {
        }
      }
    }, [widget, widget.id, s2Ref.current]);

    useEffect(() => {
      emitter.on("download", downloadListener)
      return () => {
        emitter.off("download", downloadListener);
      }
    }, [])

    if (!dataCfg) {
      return <div></div>;
    }

    const onDataCellHover = ({ event, viewMeta }) => {
      viewMeta.spreadsheet.tooltip.show({
        position: {
          x: event.clientX,
          y: event.clientY,
        },
        content: (
          <TableDataCellTooltip
            datas={viewMeta.data}
            meta={viewMeta.spreadsheet.dataCfg.meta}
          />
        ),
      });
    };

    return (
      <StyledAntVS2Wrapper
        sheetType="pivot"
        dataCfg={dataCfg}
        options={options}
        themeCfg={{ theme, palette }}
        onDataCellHover={onDataCellHover}
        header={{
          style: {marginRight: 30},
          exportCfg: {
            open: true,
          },
        }}
        ref={s2Ref}
      />
    );
  }
);

const TableDataCellTooltip: FC<{
  datas?: object;
  meta?: Array<{ field: string; name: string; formatter }>;
}> = ({ datas, meta }) => {
  if (!datas) {
    return null;
  }

  return (
    <StyledTableDataCellTooltip>
      {(meta || [])
        .map(m => {
          const uniqKey = m?.field;
          if (uniqKey in datas) {
            return (
              <li key={uniqKey}>{`${m?.name}: ${m?.formatter(
                datas[uniqKey],
              )}`}</li>
            );
          }
          return null;
        })
        .filter(Boolean)}
    </StyledTableDataCellTooltip>
  );
};

const TableDownload: FC<{ sp: SpreadSheet }> = ({ sp }) => {
  const data = copyData(sp, '\t', false)

  const onDownload = () => {
    download(data, 'filename')
  }

  return (<Button onClick={onDownload}>格式化下载</Button>)
};

const StyledTableDataCellTooltip = styled.ul`
  padding: 4px;
  font-size: ${FONT_SIZE_LABEL};
  color: ${p => p.theme.textColorLight};
`;

const StyledAntVS2Wrapper = styled(SheetComponent)``;

export default AntVS2Wrapper;
