import {InfoCircleOutlined, MessageOutlined, QuestionCircleOutlined} from '@ant-design/icons';
import {Card, Tooltip} from 'antd';
import React, {FC, memo, useCallback, useEffect, useMemo, useState} from 'react';
import { Resizable } from 'react-resizable';
import styled from 'styled-components/macro';
import { BLUE, LEVEL_1 } from 'styles/StyleConstants';
import {Popup} from "../../Popup";
import { MeasureRelationModel } from "../../../pages/MainPage/pages/ViewPage/slice/types";

export const TableComponentsTd = ({
  children,
  useColumnWidth,
  ...rest
}: any) => {

  if (rest.className.includes('ellipsis') && useColumnWidth) {
    return (
      <Tooltip placement="topLeft" title={children}>
        <Td {...rest}>{children}</Td>
      </Tooltip>
    );
  }
  return <Td {...rest}>{children}</Td>;
};

export const ResizableTitle = props => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <ResizableHandleStyle
          onClick={e => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
    >
      <th {...restProps} />
    </Resizable>
  );
};

export const TableColumnTitle: FC<{
  desc?: string,
  title: string,
  uid: string,
  columnName: string,
  viewMeasureRelation: MeasureRelationModel[],
  measureEnable?: string,
}> = memo((props) => {
  const { desc, title, uid, columnName, viewMeasureRelation, measureEnable } = props;
  const [measureName, setMeasureName] = useState<string>('');
  const [measureDescription, setMeasureDescription] = useState<string>('');
  const [measureExpression, setMeasureExpression] = useState<string>('');

  useEffect(() => {
    loadMeasureInfo();
  });

  const loadMeasureInfo = useCallback(async () => {
    if (viewMeasureRelation) {
      const index = viewMeasureRelation.findIndex(item => item.columnName === columnName)
      if (index > -1) {
        setMeasureName('指标名称：' + viewMeasureRelation[index].measureName);
        setMeasureDescription('指标定义：' + viewMeasureRelation[index].measureDescription)
        setMeasureExpression(viewMeasureRelation[index].measureExpression ? '指标公式：' + viewMeasureRelation[index].measureExpression : '')
      } else {
        setMeasureName("未绑定指标");
      }
    } else {
      setMeasureName("未绑定指标");
    }
  }, [columnName, setMeasureName, setMeasureDescription, setMeasureExpression]);

  // loadMeasureInfo();

  return (
    <TableColumnTitleStyle key={uid}>
      <span className="titleStyle" key={uid + 'title'}>
        {title}
      </span>
      {desc && (
        <Tooltip placement="top" key={uid + 'desc'} title={desc}>
          <InfoCircleOutlined />
        </Tooltip>
      )}
      {measureEnable && (
        <Popup trigger={['hover']}
               placement="top"
               content={
                 <Card title={measureName} style={{ width: 400 }} headStyle={{ fontSize: 14, fontWeight: 'bold' }} bodyStyle={{ fontSize: 14 }}>
                 <p>{measureDescription}</p>
                 <p>{measureExpression}</p>
                </Card>}>
          {/*<QuestionCircleOutlined />*/}
          {measureName != '未绑定指标' && <QuestionCircleOutlined onMouseOver={() => loadMeasureInfo()} />}
        </Popup>
      )}
    </TableColumnTitleStyle>
  );
})

// export const TableColumnTitle = props => {
//   const { desc, title, uid, measureEnable, columnName, viewId } = props;
//   const a = typeof measureEnable
//   console.log("measureEnable", a);
//   // const [measureInfo, setMeasureInfo] = useState<string>('');
//   const loadMeasureInfo = async () => {
//     const {data} = await request2<MeasureRelationModel[]>({
//       url: `/views/measure/relations/${viewId}`,
//       method: 'GET',
//     })
//     if (data) {
//       const index = data.findIndex(item => item.columnName === columnName)
//       if (index && index > -1) {
//         // setMeasureInfo(data[index].measureName);
//       } else {
//         // setMeasureInfo("未绑定指标");
//       }
//     } else {
//       // setMeasureInfo("未绑定指标");
//     }
//   };
//   return (
//     <TableColumnTitleStyle key={uid}>
//       <span className="titleStyle" key={uid + 'title'}>
//         {title}
//       </span>
//       {desc && (
//         <Tooltip placement="top" key={uid + 'desc'} title={desc}>
//           <InfoCircleOutlined />
//         </Tooltip>
//       )}
//       {measureEnable === 'true' && (
//         <Popup trigger={['click']}
//                placement="bottomRight"
//                content={<span></span>}>
//           <InfoCircleOutlined onClick={() => loadMeasureInfo()} />
//         </Popup>
//       )}
//     </TableColumnTitleStyle>
//   );
// };


const TableColumnTitleStyle = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  .titleStyle {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding:3px 0;
  }
`;

const ResizableHandleStyle = styled.span`
  position: absolute;
  right: -5px;
  bottom: 0;
  z-index: ${LEVEL_1};
  width: 10px;
  height: 100%;
  cursor: col-resize;
`;
const Td = styled.td<any>`
  ${p =>
    p.isLinkCell
      ? `
    :hover {
      color: ${BLUE};
      cursor: pointer;
    }
    `
      : p.isJumpCell
      ? `
        :hover {
          color: ${BLUE};
          cursor: pointer;
          text-decoration: underline;
        }
      `
      : null}
`;
